import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase } from '@material-ui/core';
import VanillaTilt from 'vanilla-tilt';
import { Link } from 'gatsby';
import Img from 'gatsby-image';


const useStyles = makeStyles(theme => ({
  container: {
    width: '270px',
    height: '270px',
    '&:hover': {
      '& $imageBackdrop': {
        opacity: '0.5'
      },
      '& $albumName': {
        visibility: 'visible'
      }
    }
  },
  button: {
    width: '100%',
    height: '100%'
  },
  image: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0,
    transition: theme.transitions.create('opacity')
  },
  albumName: {
    color: theme.palette.common.white,
    fontSize: '1.5em',
    border: '1.5px solid white',
    zIndex: '3',
    visibility: 'hidden',
    padding: '2px 4px',
    textAlign: 'center',
    lineHeight: '1.2em'
  }
}));

export default function AlbumCover({ name, imgData, to }) {
  const classes = useStyles();

  const tiltRef = useRef();

  useEffect(() => {
    const tiltNode = tiltRef.current;

    VanillaTilt.init(tiltNode);

    return () => {
      tiltNode.vanillaTilt.destroy();
    }
  }, [])

  return (
    <div ref={tiltRef} className={classes.container}>
      <ButtonBase className={classes.button} component={Link} to={to}>
        <span className={classes.albumName}>{name}</span>
        <div className={classes.image}>
          <Img fluid={imgData.fluid} alt="Album cover image" />
        </div>
        <span className={classes.imageBackdrop} />
      </ButtonBase>
    </div>
  );
}
