import React from "react";
import AlbumCover from "../components/AlbumCover";
import Layout from "../components/Layout";
import { getSlug } from "../utils/utils";
import { makeStyles } from "@material-ui/core/styles";
import { graphql } from "gatsby";
import SEO from "../components/seo";

const useStyles = makeStyles((theme) => ({
  albumList: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 270px)",
    gridGap: "1.2em",
    justifyContent: "center",
    marginTop: "2em",
    marginBottom: "2em",
  },
}));

export default function AlbumList({ data }) {
  const fileImageData = data.allGoogleStorage.edges.map(({ node }) => ({
    name: node.name,
    fluid: node.localFile.childImageSharp.fluid,
  }));

  function getCoverImgData(node) {
    return fileImageData.find((element) => element.name.includes(node.imgName));
  }

  const classes = useStyles();

  return (
    <Layout>
      <SEO title="Official website of Pilvikone band. Find all released music here." />
      <div className={classes.albumList}>
        {data.allAlbum.edges.map(({ node }) => (
          <AlbumCover
            key={node.id}
            imgData={getCoverImgData(node)}
            name={node.name}
            to={`/music/${getSlug(node.name, " ", "_")}`}
          />
        ))}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query {
    allAlbum(sort: { fields: releaseDateTime, order: DESC }) {
      edges {
        node {
          id
          imgSrc
          imgName
          name
        }
      }
    }
    allGoogleStorage(
      filter: { metadata: { contentType: { eq: "image/jpeg" } } }
    ) {
      edges {
        node {
          name
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
